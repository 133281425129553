import "lazysizes";
// import "./nav.js";

import { debounce, normalizeVH } from "./bundle/rcobiella-helpers.js";
import { gsap, TweenLite, CSSPlugin } from "gsap";
import Swup from "swup";
// import SwupDebugPlugin from "@swup/debug-plugin";
import SwupBodyClassPlugin from "@swup/body-class-plugin";
import SwupJsPlugin from "@swup/js-plugin";
import SwupPreloadPlugin from "@swup/preload-plugin";

gsap.registerPlugin(CSSPlugin);
//import "./navigation.js";

normalizeVH();

window.onresize = () => {
	normalizeVH();
};

/* -------------------------------------------------------------------------- */
/*                              LazySizes config                              */
/* -------------------------------------------------------------------------- */

window.lazySizesConfig = {
	loadMode: 3,
	expand: 1000,
};

jQuery(function ($) {
	var forms = document.querySelectorAll("#dl-form");
	for (var i = 0; i < forms.length; i++) {
		forms[i].setAttribute("novalidate", true);
	}

	let scrollPosition = false;
	let tween = false;

	const swupJsOptions = [
		{
			from: "(.*)",
			to: "(.*)",

			in: function (next) {
				if (scrollPosition || scrollPosition === 0) {
					window.scrollTo(0, scrollPosition);
				}

				document.querySelector("#swup").style.opacity = 0;
				TweenLite.to(document.querySelector("#swup"), 0.5, {
					opacity: 1,
					onComplete: next,
				});
			},
			out: (next) => {
				scrollPosition = 0;
				// $("#swup").css("opacity", "0");
				// document.querySelector("#swup").style.opacity = 0;
				TweenLite.to(document.querySelector("#swup"), 0.5, {
					opacity: 0,
					onComplete: next,
				});
				// setTimeout(function () {
				// 	next();
				// }, 1000);
			},
		},
		{
			from: "(.*)",
			to: "side",

			in: function (next) {
				console.log("side in", scrollPosition);
				if (scrollPosition || scrollPosition === 0) {
					window.scrollTo(0, scrollPosition);
				}
				// $(".view-player").get(0).style.opacity = 0;
				$(".view-player").get(0).style.opacity = 1;
				setTimeout(function () {
					$(".view-player").addClass("is-active");
					$("body").addClass("player-is-active");
					next();
				}, 200);

				// $(".view-player").get(0).style.opacity = 0;
				// $(".view-player").addClass("is-active");
				// $("body").addClass("player-is-active");
				// TweenLite.to(".view-player", 0.5, {
				// 	opacity: 1,
				// 	onComplete: function () {
				// 		setTimeout(function () {
				// 			$(".view-player").addClass("is-active");
				// 			$("body").addClass("player-is-active");
				// 		}, 1000);
				// 		next();
				// 	},
				// });
			},
			out: function (next) {
				scrollPosition = window.scrollY;

				console.log("side out", scrollPosition);
				$(".view-player").get(0).style.opacity = 0;
				$(".view-player").removeClass("is-active");
				$("body").removeClass("player-is-active");

				// TweenLite.to(".view-player", 0.5, {
				// 	opacity: 0,
				// 	onComplete: next,
				// });
				setTimeout(function () {
					next();
				}, 1000);
			},
		},
	];

	const swupOptions = {
		plugins: [
			// new SwupDebugPlugin(),
			new SwupPreloadPlugin(),
			new SwupBodyClassPlugin(),
			new SwupJsPlugin(swupJsOptions),
		],
		containers: ["#swup"],
		animateHistoryBrowsing: true,
		linkSelector:
			'a[href^="' +
			window.location.origin +
			'"]:not([data-no-swup]), a[href^="/"]:not([data-no-swup]), a[href^="#"]:not([data-no-swup])',
	};
	const swup = new Swup(swupOptions);

	TweenLite.to(document.querySelector("#swup"), 0.5, {
		opacity: 1,
	});
	if ($(".view-player").length) {
		TweenLite.to(".view-player", 0.25, {
			opacity: 1,
		});
	}

	$(document).on("swup:pageView", function (event) {
		TweenLite.to(document.querySelector("#swup"), 0.5, {
			opacity: 1,
		});

		if ($(".view-player").length) {
			TweenLite.to(".view-player", 0.25, {
				opacity: 1,
			});
		}

		// init stats
		if ($(".audio-player").length) {
			$("audio.audio-player").postLikes();
		}
		// $("#swup").css("opacity", "1");

		// if ($(".view-player").length) {
		// 	$(".view-player").css("opacity", "1");
		// }
	});

	/* -------------------------------------------------------------------------- */
	/*                                Mobile player                               */
	/* -------------------------------------------------------------------------- */
	$("body").on("click", function (e) {
		let $target = $(e.target);
		if (TOUCH && $(this).hasClass("player-is-active")) {
			// If we click in Library
			if (
				$target.hasClass("view-library") ||
				$target.hasClass("view-tracks")
			) {
				$("body").addClass("player-is-hidden");
			}
			// If we click in player
			if (
				$target.hasClass("view-player") ||
				$target.parents(".view-player").length
			) {
				$("body").removeClass("player-is-hidden");
			}
		}
	});

	/* -------------------------------------------------------------------------- */
	/*                               Library buttons                              */
	/* -------------------------------------------------------------------------- */
	var $libButtons = $(".library-buttons .button"),
		$searchButton = $(".button.button-search"),
		$searchInput = $(".button-search input");

	$("body").on("click", ".library-buttons .button", checkButtons);

	function checkButtons() {
		var $libButtons = $(".library-buttons .button"),
			$searchButton = $(".button.button-search"),
			$searchInput = $(".button-search input");
		let $dis = $(this);

		// Browse buttons
		if (!$dis.hasClass("button-search")) {
			let content = $dis.data("content"),
				$contentEl = $(".library-list.list-" + content);
			if ($contentEl.length) {
				$dis.toggleClass("is-active");
				if ($dis.hasClass("is-active")) {
					$dis.siblings(".button[data-content]").removeClass(
						"is-active"
					);
				}
				updateLibraryButtons();
			}
		} else {
			// Search button
			$dis.addClass("is-searching");
			$dis.find("input").focus();
			$("body").on("click", checkSearch);
		}
	}

	function checkSearch(e) {
		var $libButtons = $(".library-buttons .button"),
			$searchButton = $(".button.button-search"),
			$searchInput = $(".button-search input");
		if (!$(e.target).is("input")) {
			if (!$searchInput.val()) {
				$searchButton.removeClass("is-searching");
				$("body").off("click", checkSearch);
			}
		}
	}

	function updateLibraryButtons() {
		var $libButtons = $(".library-buttons .button"),
			$searchButton = $(".button.button-search"),
			$searchInput = $(".button-search input");
		if ($libButtons.length) {
			$libButtons.not(".button-search").each(function () {
				let $dis = $(this),
					content = $dis.data("content"),
					$contentEl = $(".library-list.list-" + content);
				if ($contentEl.length) {
					if ($dis.hasClass("is-active")) {
						$contentEl.show();
					} else {
						$contentEl.hide();
					}
				}
			});
			if (
				!$(".library-buttons .button:not(.button-search).is-active")
					.length
			) {
				$(".library-list").show();
			}
		}
	}

	// Search
	$("body").on("input", ".button-search input", function () {
		var $libButtons = $(".library-buttons .button"),
			$searchButton = $(".button.button-search"),
			$searchInput = $(".button-search input");
		let $dis = $(this),
			$searchItems = $(".library-item[data-term]");
		if ($searchButton.hasClass("is-searching")) {
			let val = $dis.val().toLowerCase();
			if (val && val.length > 0) {
				$(".is-separator").hide();
				$searchItems.each(function () {
					let $item = $(this),
						term = $item.attr("data-term");
					term.search(val) < 0 ? $item.hide() : $item.show();
				});
			} else {
				$(".is-separator").show();
				$searchItems.show();
			}
		}
	});

	// Esc key
	$("body").on("keydown", ".button-search input", function (evt) {
		var $libButtons = $(".library-buttons .button"),
			$searchButton = $(".button.button-search"),
			$searchInput = $(".button-search input");
		evt = evt || window.event;
		if (evt.keyCode == 27) {
			$searchInput.val("");
			$searchButton.removeClass("is-searching");
			$("body").off("click", checkSearch);
		}
	});
	/* -------------------------------------------------------------------------- */
	/*                                    Plyr                                    */
	/* -------------------------------------------------------------------------- */

	/* -------------------------------------------------------------------------- */
	/*                                 Home hovers                                */
	/* -------------------------------------------------------------------------- */

	let imageTimeout;
	$("body").on("mouseenter mouseleave", ".works-list-item", function (e) {
		const $dis = $(this);
		const id = $dis.data("id");
		const $img = $(".home-images-" + id);

		if (e.type == "mouseenter") {
			$(".home-images-item.is-active").removeClass("is-active");
			let t = 0;
			if (imageTimeout) {
				t = 250;
				clearTimeout(imageTimeout);
			}
			imageTimeout = setTimeout(function () {
				$img.addClass("is-active");
			}, t);
		} else {
			$img.removeClass("is-active");
		}
	});

	/* -------------------------------------------------------------------------- */
	/*                            Works mobile credits                            */
	/* -------------------------------------------------------------------------- */
	$("body").on("click", ".sm-credits-trigger", function () {
		$(this).siblings(".sm-credits").slideToggle();
	});

	/* -------------------------------------------------------------------------- */
	/*                              isMobile & TOUCH                              */
	/* -------------------------------------------------------------------------- */

	var TOUCH = "ontouchstart" in window;

	var isMobile = window.innerWidth <= 1024;

	const checkMobile = () => {
		isMobile = window.innerWidth <= 1024;
	};
	window.addEventListener("resize", debounce(checkMobile, 20));

	var $cursor;

	/* -------------------------------------------------------------------------- */
	/*                                Loader + AOS                                */
	/* -------------------------------------------------------------------------- */

	let ignore_unload = false;

	// Prevent if link has mailto
	$("body").on("click", "a[href^=mailto], a[href^=tel]", function () {
		ignore_unload = true;
	});

	$(window).on("beforeunload", function (e) {
		if (!ignore_unload) {
			$("#loader").removeClass("is-loaded");
		}
		ignore_unload = false;
	});

	$(window).on("load", function () {
		setTimeout(function () {
			let hash = location.hash;
			if (hash) {
				hash = hash.replace("#", "");
				let scrollToNode = document.getElementById(hash);
				if (scrollToNode) {
					window.scrollTo({
						top: scrollToNode.offsetTop,
						behavior: "auto",
					});
				}
			}

			// Remove preload-transitions class from body to re-enable transitions
			$("body").removeClass("preload-transitions");
			$("#loader").addClass("is-loaded");
			if (
				TOUCH &&
				($("body.tax-genres").length || $("body.tax-playlists").length)
			) {
				$(".view-player").addClass("is-active");
				$("body").addClass("player-is-active");
			}
		}, 0);
	});
});
